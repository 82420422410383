"use client"; // Because we're inside a server component

import { createContext, useContext, useMemo, useState } from "react";

import { Availability } from "@/functions/shared/type/availability/availability";
import { Plan } from "@/type/plan";

// Create the context
const AppContext = createContext(null);
// Create a provider component
const AppProvider = ({ children, existingPlan = null }) => {
    const [globalPlan, setGlobalPlan]: [
        plan: Plan,
        setter: (plan: Plan) => void
    ] = useState<Plan | null>(existingPlan);

    const [globalCanSave, setGlobalCanSave]: [
        canSave: boolean,
        setter: (canSave: boolean) => void
    ] = useState<boolean>(true);

    const [initialPlannerOpen, setInitialPlannerOpen]: [
        initialPlannerOpen: boolean,
        setter: (initialPlannerOpen: boolean) => void
    ] = useState<boolean>(false);

    const [bookingAvailabilitySelected, setBookingAvailabilitySelected]: [
        bookingAvailability: Availability | null,
        setter: (bookingAvailability: Availability) => void
    ] = useState<any>(null);

    // Define any functions or values you want to provide
    const value = useMemo(
        () => ({
            globalPlan,
            setGlobalPlan,
            globalCanSave,
            setGlobalCanSave,
            initialPlannerOpen,
            setInitialPlannerOpen,
            bookingAvailabilitySelected,
            setBookingAvailabilitySelected,
        }),
        [
            globalPlan,
            setGlobalPlan,
            globalCanSave,
            setGlobalCanSave,
            initialPlannerOpen,
            setInitialPlannerOpen,
            bookingAvailabilitySelected,
            setBookingAvailabilitySelected,
        ]
    );

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

const useAppContext = () => useContext(AppContext);

export { AppProvider, useAppContext };
