import { HttpMethod, HttpStatus } from "http-status-ts";
import { useEffect, useRef, useState } from "react";

import { Plan } from "@/type/plan";
import { useAppContext } from "@/app/AppContext";
import { verboseLog } from "@/lib/utils";

const attemptSave = async (
    plan: Plan,
    setUpdatedPlan: (plan) => void,
    setFailureReason: (string) => void
) => {
    try {
        const response = await fetch(
            plan.planId ? `/api/plans/${plan.planId}` : "/api/plans",
            {
                method: plan.planId ? HttpMethod.POST : HttpMethod.PUT,
                headers: {
                    "Content-Type": "application/json",
                    credentials: "include",
                },
                body: JSON.stringify(plan),
            }
        );

        if (response.status !== HttpStatus.OK) {
            verboseLog("response.status", response.status);
            setFailureReason(
                `We were unable to create a plan ${JSON.stringify(response)}`
            );
            return;
        }

        const reponseJson = await response.json();

        setUpdatedPlan(reponseJson);
    } catch (e) {
        verboseLog("Error in attemptSave", JSON.stringify(e));
        setFailureReason("We were unable to create a plan");
    }
};

const usePlanFormSave = () => {
    const { globalPlan, setGlobalPlan } = useAppContext();

    const canSaveRef = useRef(true);
    const [canSave, setCanSave] = useState(true);
    const [updatedPlan, setUpdatedPlan] = useState<Plan>(null);
    const [failureReason, setFailureReason] = useState(null);

    useEffect(() => {
        if (updatedPlan?.planId) {
            setGlobalPlan(updatedPlan);
        }
    }, [updatedPlan]);

    useEffect(() => {
        verboseLog("globalPlan effect triggered", globalPlan);
    }, [globalPlan]);

    return {
        canSave,
        saveProvidedPlan: async (plan) => {
            if (canSaveRef.current) {
                canSaveRef.current = false;
                setCanSave(false);
                await attemptSave(plan, setUpdatedPlan, setFailureReason);
                canSaveRef.current = true;
                setCanSave(true);
            }
        },
        updatedPlan,
        failureReason,
        globalPlan,
    };
};

export { usePlanFormSave };
