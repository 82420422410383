import { useEffect, useState } from "react";

const useDebounceStateChange = (
    dependencies: any[],
    onStartCallback: (...args) => any,
    onCompletionCallback: (...args) => any,
    timeoutInMs: number
) => {
    const [firstRender, setFirstRender] = useState(true);

    useEffect(() => {
        if (firstRender) {
            setFirstRender(false);
            return;
        }

        const timer = setTimeout(onCompletionCallback, timeoutInMs);

        onStartCallback();

        return () => {
            if (timer) clearTimeout(timer);
        };
    }, dependencies);
};

export { useDebounceStateChange };
